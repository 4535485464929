import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import Index2Slider from '../../components/Dashboard/Index2/Index2Slider';
import { getTokensAction } from '../../../store/actions/tokens/TokenActions';
import axiosInstance from '../../../services/AxiosInstance';
import WalletModal from '../../components/Wallet/Wallet';
import { toastError } from '../../../services/ToastService';
import { displayDecimal } from '../../../helpers/Utils';
import { getProjectByApiKey } from '../../../services/PostsService';
import { Modal } from 'react-bootstrap';

const Dashboard2 = ({ tokens }) => {
  const dispatch = useDispatch();
  const [balance, setTotalBalance] = React.useState(null);
  const [openModal, setOpenModal] = useState(false);
  //url from mobile redirection
  const [url, setURL] = useState(null);
  //selecte project from mobile url
  const [selectedProject, setSelectedProject] = useState(null);
  const [loading, setLoading] = useState(false);

  const tokenDetailsString = localStorage.getItem('userDetails');
  const redirectUrl = localStorage.getItem('redirectUrl');
  const apiKey = localStorage.getItem('apiKey');

  const userDetails = JSON.parse(tokenDetailsString);
  useEffect(() => {
    if (apiKey) {
      setURL({
        ...url,
        apiKey: apiKey,
      });
      setOpenModal(true);
    }
    if (redirectUrl) {
      setURL({
        ...url,
        url: redirectUrl,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectUrl, apiKey]);

  useEffect(() => {
    //get project based on apikey from url
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getProjectByApiKey(url?.apiKey);
        setSelectedProject(response?.data?.data?.project);
        setLoading(false);
      } catch (error) {
        console.log(error?.response?.data?.message);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    if (url?.apiKey) {
      fetchData();
    }
  }, [url?.apiKey]);

  useEffect(() => {
    dispatch(getTokensAction());
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get('market/total-balance');
        setTotalBalance(response.data.data);
      } catch (error) {
        toastError(error?.response?.data?.message);
      }
    };

    fetchData();
  }, []);

  const handleAccept = () => {
    if (redirectUrl) {
      const modifiedUrl = new URL(url?.url);
      if (selectedProject) {
        modifiedUrl.searchParams.append('token', userDetails?.token);
        modifiedUrl.searchParams.append('email', userDetails?.user?.email);
      } else {
        modifiedUrl.searchParams.append('message', ' something went wrong');
      }
      window.open(encodeURI(modifiedUrl));
    } else {
      const iframe_message = {
        statusCode: selectedProject ? 200 : 400,
        message: selectedProject ? 'success' : 'something went wrong',
        data: selectedProject
          ? {
              token: userDetails?.token,
              email: userDetails?.user?.email,
            }
          : null,
      };

      window.parent.postMessage(JSON.stringify(iframe_message), '*');
      setOpenModal(false);
    }
    localStorage.removeItem('redirectUrl');
    localStorage.removeItem('apiKey');
  };
  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="row main-card">
            <div className="col-xxl-12 col-lg-12">
              <div className="row">
                <div className="col-xxl-6 col-lg-6">
                  <div>
                    <Index2Slider
                      data={[
                        {
                          id: 2,
                          bgColor: 'bg-primary',
                          title: 'SENET',
                          svgcolor: '#1ABAFF',
                          usdBalance: balance?.usd.toFixed(3),
                          sntBalance: balance?.snt?.toFixed(3),
                        },
                      ]}
                    />
                  </div>

                  <div className="row">
                    <div className="col-xxl-12 col-lg-12 col-md-12 col-sm-12">
                      <div className="card market-previews-2">
                        <div className="card-header border-0 pb-0">
                          <div>
                            <h2 className="heading">Tokens</h2>
                          </div>
                        </div>
                        <div className="card-body pt-0 px-0">
                          {tokens.map((token, ind) => (
                            <div className="previews-info-list" key={ind}>
                              <div className="pre-icon">
                                <span
                                  className={`icon-box icon-box-sm ${
                                    token.symbol === 'SNT' ? 'bg-secondary' : ''
                                  } `}
                                >
                                  <img
                                    src={token.logoUri}
                                    width={30}
                                    alt="token-alt"
                                  />
                                </span>
                                <div className="ms-2">
                                  <h6>{token.name}</h6>
                                  <span>
                                    ${parseFloat(token.usdValue).toFixed(2)}
                                  </span>
                                </div>
                              </div>
                              <div className="count">
                                <h6>
                                  {/* {parseFloat(token.amount).toFixed(3)} */}
                                  {displayDecimal(token.amount)}
                                </h6>
                                <span
                                  className={
                                    ind % 2 === 0 ? 'text-success' : ''
                                  }
                                >
                                  {parseFloat(token.change24h).toFixed(3)}%
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-sm-12">
                  <WalletModal />
                  {/* TODO:The conversion modal is commented out; we might need that later. */}
                  {/* <Convert /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openModal && (
        <Modal className="fade" show={openModal} centered="true">
          <Modal.Header>
            <h4 className="text-center">Premission Access</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-1">
                <img
                  src={selectedProject?.imageUrl}
                  onerror="this.onerror=null; this.src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKQAAACUCAMAAAAqEXLeAAAAP1BMVEXp6uybm5vs7e+YmJjMzM7q6u6wsLC/v8Gmp6nZ2tubm5mUlZbW1tbm5uicnJ7v8PK1tbfX1tqioqHGxsaqqqn9Hu2DAAAChElEQVR4nO2a7bKCIBCGcSkR+Tza/V/rQTO1QmtGQZt5n5+OwOPKLkgxBgAAAAAAAAAAAAAAAAAAAAD4Oa6bIUqsuMMAZo9O1iB28eVWfGuSWpqS70FlrukcnVfFHiifTNKQ6Yeo6+2af8ksSXaBrDbShMfkl2SzspdUl429aJ5Dclv/pLNEkkKh28BvRPKckjRfYs4pSUZrycZGZ5Qk40PNaW6te1w4nyRJy7vizZUfgnlCSVMVdlgKxdDJ6SRJ8GkplHSo5OIGkfgkqdojJYmkZtFBycx2Tbx0x0mSKRWPj3oeSVaFq3Zh2NmUVOI4See79OV2yIuXFuVoadVhiUNj/tZNzFJOb9sPLfJLunZ6n2Use9rhGXgljyrmpNX4LWGHzHhpc2mK8OlV3MzYJLMk6WYKZB1SIzYtzUUIocdSmluSTDPL3u6dxsYOGzVH8wfLKkmmenYMb1x/HDyvJDH/4tiluFnvIrekE2+O3bLy6QglqyS1KiIZLCO7jesRc7I7GNMRw95SvBUiYlqbA3bmJItYHKMpHgoVn3Yg2SRDLJslx4B2z61sv7oLlnntNuWKI2/myUPy/jw19/3MzCe55hgsb1MhIm0f96o+83NJklB2TbKYVvHH1+Ld8hYsM0m6djWOvc6Q4l3OPIc411mQ/ugYdNp7DbAvi3sjXQ5JLtYSeyJsH51swrR4ujtYygySi/XxzdKFOL7NXW5FhvPJb+GlXgz5aSR70WjYG34qySUgCUlIQhKSkITkj0nW6SQZ+3ozuY6NngrvQthqf/66+YrHr2OJaAvO1UZ44RP/e0m2YjOfDzG3Wu5BasfE/QMAAAAAAAAAAAAAAAAAAAAAzsk/4Wwrn8XJz4AAAAAASUVORK5CYII=';"
                  className="img-rounded"
                  alt=""
                  width="30"
                  height="30"
                />
              </div>
              <div className="col-10 ms-3">
                <div className="text-break">
                  <span className="fs-16 text-dark ">
                    <span className="fw-bold">{selectedProject?.name}</span> -
                    would like to access the following data from your account
                  </span>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-1">
                <i class="bi bi-person fa-3x text-primary"></i>
              </div>
              <div className="col-2 align-self-center">
                {' '}
                <p className="ms-3 text-dark fs-18 fw-bold m-0 ">User</p>
              </div>
            </div>
            <div className="row ">
              <div className="col-1">
                <i class="bi bi-dot fa-3x "></i>
              </div>
              <div className="col-2 align-self-center">
                <p className="ms-3 text-dark m-0 fs-18 ">
                  {userDetails?.user?.email}
                </p>
              </div>
            </div>

            <div className="row">
              <p className="text-break">
                By clicking "Accept" you allow this app to user your information
                in accordance with their respective Term of Service and Privacy
                Policies.You can change this anytime in Connect Apps.
              </p>
            </div>

            <div className="row">
              <div className="d-flex  justify-content-center gap-2 flex-wrap mb-2">
                <div className="shopping-cart  mb-1 mt-3 ">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      setOpenModal(false);
                      localStorage.removeItem('redirectUrl');
                      localStorage.removeItem('apiKey');
                    }}
                    disabled={loading}
                  >
                    Cancel
                  </button>
                </div>
                <div className="shopping-cart  mb-1 mt-3 ">
                  <button
                    className="btn btn-primary"
                    onClick={handleAccept}
                    disabled={loading}
                  >
                    Accept
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { tokens } = state;
  return {
    tokens: tokens.tokensList,
  };
};

export default connect(mapStateToProps)(Dashboard2);
